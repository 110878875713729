import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { SearchIcon } from '../../../images/icons/SearchIcon';
import './SearchForm.scss';
const isClient = typeof window !== 'undefined';
export default class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchVal: ''
    }
  }

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { active: wasActive } = this.props;
    const { active: isActive } = nextProps;
    if (!wasActive && isActive) {
      setTimeout(() => {
        if (this.searchInput) this.searchInput.focus();
      }, 100);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleSearch, active } = this.props;
    if (event.keyCode === 27 && active) toggleSearch(event);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { toggleSearch } = this.props;
    const { searchVal } = this.state;
    if (!searchVal) return false;
    if (toggleSearch) toggleSearch();
    return navigate(`/search?query=${searchVal}`);
  }

  onChangeHandler = (e) => {
     let newState = {}
     newState[e.target.name] = e.target.value
     this.setState(newState)
  }

  render() {
    const { active, toggleSearch } = this.props;
    const { searchVal } = this.state;
    if (!active) return <div className="search-form-block" />;
    return (
      <div className="search-form-block active">
        <div className="wrap">
          <form className="search-form" name="search-form" onSubmit={this.handleSubmit}>
            <div className="form-inner">
              <div className={`header-search${searchVal ? ' active' : ''}`}>
                <label htmlFor="search">Enter search phrase here...</label>
                <input name="search" type="text" placeholder="Enter search phrase here..." name="searchVal" onChange={e => this.onChangeHandler(e)} value={searchVal} />
              </div>
              {searchVal && (
                <button type="submit" aria-label="Search" className={searchVal ? 'active' : 'not-active'}>
                  <SearchIcon />
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}
