import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby';
import './PostList.scss';
import Pagination from '../Pagination';
import { PostListItem } from '../PostListItem'
import { PostListNavigation } from '../PostListNavigation'
import SearchForm from '../../Search/SearchForm';
import { GlobalCTA } from '../../Acf/Blog/CTA/GlobalCTA'

const PostList = (props) => {
  const { posts, 
    title, 
    pageContext, 
    categoryFilter, 
    pathPrefix, 
    pathSuffix = '', 
    siteMetadata, 
    showAuthor,  
    handleChange, 
    FilterBySelector, 
    sortFilter, 
    isBlogList,
    showGlobalCTA,
  } = props
  const topPosts = posts.length > 3 ? posts.slice(0, 3) : posts

  const reducedPosts = !topPosts ? posts : posts.slice(3, posts.length);
  return (
    <section className="post-list">
      <PostListNavigation pathPrefix={pathPrefix} categoryFilter={categoryFilter} />
      {isBlogList && 
        <div className="filter-search-wrapper">
          <div className="wrap">
            <SearchForm active/>
            <FilterBySelector sortFilter={sortFilter} handleChange={handleChange} />
          </div>
        </div>
      }
      {topPosts.length > 0 && isBlogList && <TopPostBlock topPosts={topPosts} siteMetadata={siteMetadata} pathPrefix={pathPrefix} showAuthor={false}/>}
      {showGlobalCTA && <GlobalCTA />}
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            <div className="list">
              {isBlogList &&
                reducedPosts.map((post, index) => post.node.slug !== 'gatsby' &&
                  <PostListItem key={index} data={post.node} siteMetadata={siteMetadata} pathPrefix={pathPrefix} showAuthor={showAuthor} />
                )
              }
              {!isBlogList && 
                posts.map((post, index) => post.node.slug !== 'gatsby' &&
                  <PostListItem key={index} data={post.node} siteMetadata={siteMetadata} pathPrefix={pathPrefix} showAuthor={showAuthor} />
                )
              }
            </div>
            <Pagination sortFilter={sortFilter} pageContext={pageContext} pathPrefix={`/${pathPrefix}${pathSuffix}`} />
          </div>
        </div>
      </div>
    </section>
  )
}

const TopPostBlock = props => {
  const { topPosts, siteMetadata, pathPrefix, showAuthor } = props;
  if (!topPosts) return null;
  return (
    <div className="top-post-wrapper">
      <div className="top-post">
        <PostListItem siteMetadata={siteMetadata} pathPrefix={pathPrefix} data={topPosts && topPosts.length > 0 && topPosts[0].node} />
      </div>
      {topPosts && 
        <div className="top-post_additional">
          {topPosts.map((post, index) => {
            if (post.node.slug !== 'gatsby') {
              // Skip the first index
              if (index !== 0) {
                return (
                  <PostListItem key={index} data={post.node} siteMetadata={siteMetadata} pathPrefix={pathPrefix} showAuthor={showAuthor} />
                )}
              }
            }
          )}
        </div>
      }
    </div>
  )
}


export default PostList;

PostList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

PostList.defaultProps = {
  posts: null,
  title: 'A blog post title'
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    categories {
      id
      name
      slug
    }
    title
    excerpt
    date(formatString: "MMMM Do, YYYY")
    slug
    featured_image_url {
      source_url
    }
  }
`
