import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { EnquiryHeader } from '../components/Acf/EnquiryHeader'
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostList from '../components/Posts/PostList';
import { PageHeader } from '../components/PageHeader';
import { decodeEntities } from '../utils/helpers';

export default class Category extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const { postsOnPage, categoryFilter, categories, site, wordpressWpSettings, yoast = [], siteSettings } = data
    const { showAuthor, blogTopForm } = siteSettings.options
    const { edges: posts, totalCount } = postsOnPage
    const { title: siteTitle } = wordpressWpSettings
    const { name: category, slug } = pageContext
    const title = `${category}`
    const thisCategory = categories.edges.filter(i => i.node.slug === slug)
    const featuredImage = thisCategory[0] && thisCategory[0].node.featured_image_url ? categories.edges[0].node.featured_image_url : ''

    return (
      <Layout className={`page-wrap`} location={location}>
        <SEO title={`${decodeEntities(category)} | ${decodeEntities(siteTitle)}`} yoast={yoast} />
        {blogTopForm && <EnquiryHeader {...blogTopForm} />}
        <PostList
          posts={posts}
          title={title}
          pageContext={pageContext}
          categoryFilter={categoryFilter}
          siteMetadata={wordpressWpSettings}
          pathPrefix={`${wordpressWpSettings.blogSlug}/`}
          pathSuffix={`${slug}/`}
          showAuthor={showAuthor}
        />
      </Layout>
    )
  }
}

Category.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query CategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        showAuthor
        blogTopForm {
          actionText
          content
          formId
          subTitle
          title
        }
      }
    },
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          id
        }
      }
    }
    postsOnPage: allWordpressPost(
      filter: {
        categories: {elemMatch: {slug: {eq: $slug}}},
        slug: { ne: "dummy-post" }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          featured_image_url {
            source_url
          }
          count
        }
      }
    }
  }
`
