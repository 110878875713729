import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { slugToTitle } from '../../utils/helpers';
import GatsbyLink from '../GatsbyLink';
import './Breadcrumbs.scss';

export const Breadcrumbs = (props) => {
  const {
    crumbLabel,
    location,
  } = props;

  if (!location) {
    return null;
  }

  const { pathname } = location;
  const pathArray = pathname.split('/');
  let urlBuild = [''];
  let activePath = pathArray.filter(path => path != "")  
  let activeUrl = activePath[activePath.length - 1]
  
  const regex = new RegExp(/-/g)

  return (
    <div className="breadcrumbs">
      <div className="inner">
        <ul>
          <li><GatsbyLink to={'/'}>Home</GatsbyLink></li>
          {pathArray && pathArray.filter(item => item != '').map((item, index) => {
            urlBuild.push(item);
            return (
              <li className={item === activeUrl ? `active-breadcrumb` : ``}key={index}>
                <span>/</span><GatsbyLink to={`${urlBuild.join('/')}/`} decode={true}>{item.replace(regex, ' ')}</GatsbyLink>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  );
}
