import React from 'react'
import './Pagination.scss';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { Link } from 'gatsby'

const Pagination = ({ pageContext, pathPrefix, sortFilter }) => {

  if (!pageContext) {
    return null;
  }
  const { previousPagePath = null, nextPagePath = null, numberOfPages = null } = pageContext
  let pages = [];
  /**
   * We don't want to show all pages, rather a range from 1st, second up to n pages.
   */
  for (let i = 0; i < numberOfPages; i++) {
    pages.push(i+1);
  }
  return (
    <nav className="pagination" role="navigation">
      <div className="navbar navbar-menu">
        {previousPagePath && (
          <Link state={{filter: sortFilter}} to={previousPagePath} className="prev"><FaChevronLeft/></Link>
        )}
        {pages.map((key, index) => {
          return (
            <Link 
              state={{filter: sortFilter}} 
              key={index} 
              to={
                key !== 1 
                ? ( `${pathPrefix}page/${key}/` )
                : ( `${pathPrefix}` )
                } 
              activeClassName="active"
            >
              {key}
            </Link>
          )}
        )}
        {nextPagePath && (
          <Link state={{filter: sortFilter}} to={`${nextPagePath}/`} className="next"><FaChevronRight/></Link>
        )}
      </div>
    </nav>
  )
}

export default Pagination
